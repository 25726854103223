import { Button, Divider, Flex, FormControl, FormLabel, Heading, Input, List, ListItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Tag, TagCloseButton, TagLabel, useDisclosure, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { JButton, JGoogleIcon, JInput, JToast, JTypography } from '../../../../components';
import { useTranslation } from 'react-i18next';
import { Select as ChakraReactSelect } from 'chakra-react-select'
import { useMutation, useQuery } from 'react-query';
import { ApiGetGroupMembersProps, apiGetGroupMembers } from '../../../../apis/memberGroupApi';
import { headingTheme } from '../../../../theme/components';
import { privilegeTemplate } from '../../../../apis/template';
import { listLicense } from '../../../../apis/license';
import { useDebounce } from 'use-debounce';
import { userSearch, userSearchProps } from '../../../../apis/user';
import { inviteMember, inviteMemberProps } from '../../../../apis/memberApi';
import moment from 'moment';
import { format, isSameDay, isTomorrow, isYesterday, startOfToday } from 'date-fns';
import { id, enAU } from 'date-fns/locale'

function ModalMemberInvite() {
    const { isOpen, onOpen, onClose } = useDisclosure()

    const { t } = useTranslation()
    const toast = useToast()
    const [active, setActive] = useState("")
    const [searchbar, setSearchbar] = useState('')
    const [valid, setValid] = useState(false)
    const [search] = useDebounce(searchbar, 500);

    const [getGroupMembersConfig, setGetGroupMembersConfig] = useState<ApiGetGroupMembersProps>({
        page: 1,
        search: '',
    })

    const [configSearch, setConfigSearch] = useState<userSearchProps>({
        keyword: ""
    })

    const [formInvite, setFormInvite] = useState<any>({
        akses: null,
        group: null,
        license: { label: "", value: "" },
        recipient: "",
        code_name: ""
    })

    const [listInvite, setListInvite] = useState<any[]>([])
    const [filterLicence, setFilterLicense] = useState<any[]>([])

    const groupMembers = useQuery('member-group-get-group-members', handleGetGroupMembers)
    const privilegeTemp = useQuery('privilege-Template', handelGetPrevilegeTemplate)
    // const license = useQuery('license-list', handelGetLicense)
    const license = useQuery('license-list', handelGetLicense, {
        onSuccess(data) {
            setFilterLicense(
                data.filter((data: any) => moment(data.expired_date).isAfter(new Date(), 'hour') && data.quantity > data.quantity_taken)
            )
        },
    })
    const searchUser = useQuery('user-search', handleSearch)
    const invite = useMutation(inviteMember)

    async function handleGetGroupMembers() {
        return await apiGetGroupMembers(getGroupMembersConfig)
    }

    async function handelGetPrevilegeTemplate() {
        return await privilegeTemplate({})
    }
    async function handelGetLicense() {
        return await listLicense({})
    }
    async function handleSearch() {
        return await userSearch({ ...configSearch })
    }

    function handleMemberInvite() {
        invite.mutate({
            privilege_template_id: formInvite.akses?.value,
            group_member_id: formInvite.group?.value,
            license_id: formInvite.license?.value,
            recipient: JSON.stringify(
                listInvite.length > 0 ? listInvite.map((list: any) => {
                    return {
                        type: "email",
                        value: list.recipient
                    }
                }) :
                    [{
                        type: "email",
                        value: formInvite.recipient
                    }]
            ),
            toast: toaster
        })
        setValid(false)
        if (formInvite.recipient !== '' || listInvite.length !== 0) {
            handleCloseModal();
        }
    }

    function handleCloseModal() {
        setFormInvite({
            akses: null,
            group: null,
            license: { label: license.data[0].code, value: license.data[0].id },
            recipient: "",
            code_name: ""
        })
        setListInvite([])
        setActive("0")
        setConfigSearch({
            keyword: ""
        })
        onClose()
    }

    function handleChange(e: any) {
        setSearchbar(e.target.value)
        const email = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        email.test(e.target.value) ?
            setFormInvite((prev: any) => {
                return {
                    ...prev,
                    recipient: e.target.value,
                    code_name: e.target.value
                }
            }) :
            setFormInvite((prev: any) => {
                return {
                    ...prev,
                    code_name: e.target.value,
                }
            })
    }

    function handleEnter(e: any) {
        if (e.key === "Enter") {
            if (e.target.value !== "" && formInvite.recipient !== "") {
                if (!listInvite.some((item) => item.recipient === formInvite.recipient)) {
                    setActive("0")
                    setListInvite((prev: any) => [
                        ...prev, {
                            recipient: formInvite.recipient,
                            code_name: formInvite.code_name,
                        }
                    ])
                }
            }
            if (formInvite.recipient !== "") {
                setFormInvite((prev: any) => {
                    return {
                        ...prev,
                        recipient: [],
                        code_name: []
                    }
                })
            }
        }
    }

    function handleDeleteInvite(c: any) {
        setListInvite((prev: any) => prev.filter((data: any) => data.code_name !== c))
    }

    function toaster(code: string, msg: string) {
        toast({
            position: 'top-right',
            render: () => (
                <JToast
                    type={code === "200" ? 'success' : 'error'}
                    message={t(msg)}
                />
            ),
            duration: 1500,
            isClosable: true,
        })
    }

    function handleGetDate(time: string) {
        if (!time) return
        if (isSameDay(new Date(time), startOfToday())) {
            return `${t('notifikasi_hari_ini')}`
        } else if (isTomorrow(new Date(time))) {
            return t('master_anggota_expired_next')
        } else if (!moment(time).isAfter(new Date(), 'hour')) {
            return
        } else {
            return format(new Date(time), 'dd MMM yyyy', {
                locale: document.cookie.replace(/(?:(?:^|.*;\s*)i18next\s*\=\s*([^;]*).*$)|^.*$/, "$1") === 'id'
                    ? id
                    : enAU
            })
        }
    }

    useEffect(() => {
        setConfigSearch({
            keyword: search
        })
    }, [search])

    useEffect(() => {
        searchUser.refetch()
    }, [configSearch])

    const CHAKRA_REACT_SELECT_STYLE: any = {
        option: (provided: any) => ({
            ...provided,
            ...headingTheme?.sizes?.chakra_label_large,
            backgroundColor: 'transparent',
        }),
        singleValue: (provided: any) => ({
            ...provided,
            ...headingTheme?.sizes?.chakra_label_large,
        }),
        placeholder: (provided: any) => ({
            ...provided,
            ...headingTheme?.sizes?.chakra_label_large,
        }),
        menuList: (provided: any) => ({
            ...provided,
            ...headingTheme?.sizes?.chakra_label_large,
            backgroundColor: 'background',
        }),
        menu: (provided: any) => ({
            ...provided,
            zIndex: 999,
        }),
        container: (provided: any) => ({
            ...provided,
        }),
    }

    return (
        <Flex w='max-content'>
            <Button
                margin={"16px 0"}
                leftIcon={<JGoogleIcon name={"person_add"} />}
                variant='j-primary'
                onClick={
                    () => {
                        setFormInvite((prev: any) => {
                            return {
                                ...prev,
                                license: { label: filterLicence[0].code, value: filterLicence[0].id },
                            }
                        })
                        setValid(true)
                        onOpen()
                    }}
            >
                {t("master_anggota_invites")}
            </Button>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                size={"sm"}
                closeOnOverlayClick={false}
            >
                <ModalOverlay />
                <ModalContent
                    borderRadius={'16px'}
                    backgroundColor={"background"}
                    padding={"24px"}
                >
                    <ModalHeader padding={"0px"} mt={'10px'} mb={2}>
                        <JTypography size='title-large'>
                            {t("master_anggota_invites")}
                        </JTypography>
                    </ModalHeader>
                    <ModalCloseButton top={"24px"} right={"24px"} onClick={handleCloseModal} />
                    <ModalBody
                        as={Flex}
                        flexDirection={"column"}
                        gap={"16px"}
                        padding={"0px"}
                        marginTop={"46px"}
                    >
                        <Flex
                            width={'100%'}
                            direction={'column'}
                            gap={'16px'}
                        >
                            <FormControl variant="floating" id="group">
                                <ChakraReactSelect
                                    useBasicStyles
                                    isClearable
                                    closeMenuOnSelect={true}
                                    chakraStyles={CHAKRA_REACT_SELECT_STYLE}
                                    options={groupMembers.data?.data?.map((ber: any) => { return { label: ber.display_name, value: ber.id } })}
                                    value={formInvite?.group}
                                    onChange={(e: any) => {
                                        setFormInvite((prev: any) => {
                                            return {
                                                ...prev,
                                                group: e,
                                            }
                                        })
                                    }}
                                />
                                <FormLabel
                                    transform={
                                        formInvite?.group
                                            ? 'scale(0.85) translateY(-24px)'
                                            : 'unset'
                                    }>{t("master_anggota_grup")}</FormLabel>
                            </FormControl>
                            <FormControl variant="floating" id="akses" >
                                <ChakraReactSelect
                                    useBasicStyles
                                    isClearable
                                    closeMenuOnSelect={true}
                                    chakraStyles={CHAKRA_REACT_SELECT_STYLE}
                                    options={privilegeTemp.data?.map((pri: any) => { return { label: pri.name, value: pri.id } })}
                                    value={formInvite?.akses}
                                    onChange={(e: any) => {
                                        setFormInvite((prev: any) => {
                                            return {
                                                ...prev,
                                                akses: e,
                                            }
                                        })
                                    }}
                                />
                                <FormLabel
                                    transform={
                                        formInvite?.akses
                                            ? 'scale(0.85) translateY(-24px)'
                                            : 'unset'
                                    }>{t("master_anggota_akses")}</FormLabel>
                            </FormControl>
                            <Flex flexDir='column' position='relative'>
                                {formInvite.license.value !== 0 &&
                                    <Flex alignSelf='end' bg='errorContainer' p='4px 16px 12px' rounded='8px 8px 0 0' zIndex='1'>
                                        <Heading size='chakra_label_medium' color='error'>{t('master_anggota_expired')} {handleGetDate(filterLicence?.find((x: any) => x.code == (formInvite.license.value === 0 ? x[0]?.code : formInvite.license.label))?.expired_date)}</Heading>
                                    </Flex>
                                }
                                <FormControl variant="floating" id="lisensi" position='absolute' top='24px' zIndex='2' bg='surface'>
                                    <ChakraReactSelect
                                        useBasicStyles
                                        closeMenuOnSelect={true}
                                        defaultValue={formInvite?.license}
                                        chakraStyles={CHAKRA_REACT_SELECT_STYLE}
                                        options={filterLicence.map((lis: any) => { return { label: lis.code, value: lis.id } })}
                                        value={formInvite?.license}
                                        onChange={(e: any) => {
                                            setFormInvite((prev: any) => {
                                                return {
                                                    ...prev,
                                                    license: e,
                                                }
                                            })
                                        }}
                                    />
                                    <FormLabel
                                        transform={
                                            formInvite?.license
                                                ? 'scale(0.85) translateY(-24px)'
                                                : 'unset'
                                        }>{t("master_anggota_lisensi")}</FormLabel>
                                </FormControl>
                                {formInvite.license.value !== 0 && (
                                    (() => {
                                        const getcount = filterLicence?.find((x: any) => x.code === formInvite.license.label);
                                        return (
                                            <Flex
                                                bg='linear-gradient(90deg, #0079D2 0%, #33A9FF 100%)'
                                                mt='24px'
                                                p='12px 16px 4px'
                                                rounded='0 0 8px 8px'
                                                zIndex='1'
                                                justify='space-between'
                                            >
                                                <Heading size='chakra_label_medium' color='neutral.100'>
                                                    {t('master_anggota_lisensi_use')}
                                                </Heading>
                                                <Heading size='chakra_label_medium' color='neutral.100'>
                                                    {`(${getcount?.quantity_taken || '-'} / ${getcount?.quantity || '-'})`}
                                                </Heading>
                                            </Flex>
                                        );
                                    })()
                                )}
                            </Flex>
                            <FormControl variant="floating" id="email" isInvalid={!valid} isRequired >
                                <Input
                                    autoComplete="off"
                                    placeholder=" "
                                    type='text'
                                    value={formInvite.code_name}
                                    name='text'
                                    onKeyUp={handleEnter}
                                    onChange={(e: any) => {
                                        handleChange(e)
                                        setActive("4")
                                    }}
                                    {...headingTheme?.sizes?.chakra_label_large}
                                />
                                <FormLabel>{t("master_anggota_email")}</FormLabel>
                                {active === "4" ?
                                    <List position={'absolute'} zIndex={'10'} bg={'surfacePlus2'} w={'100%'} borderRadius={'16px'} maxH={'150px'} overflow={'hidden'} overflowY={'auto'}>
                                        {searchUser.data?.map((tex: any, i: number) => {
                                            return (
                                                <ListItem key={i}>
                                                    <Button
                                                        p="12px"
                                                        w="100%"
                                                        variant="ghost"
                                                        borderRadius="16px"
                                                        _hover={{}}
                                                        onClick={(e: any) => {
                                                            if (!listInvite.some((x: any) => x.recipient === tex.username || x.code_name === tex.code)) {
                                                                setListInvite((prev: any) => [
                                                                    ...prev,
                                                                    {
                                                                        recipient: tex.username,
                                                                        code_name: tex.code,
                                                                    },
                                                                ]);
                                                            }
                                                            setFormInvite((prev: any) => ({
                                                                ...prev,
                                                                recipient: "",
                                                                code_name: "",
                                                            }));
                                                            setActive("")
                                                        }}
                                                    >
                                                        <Flex w={'100%'} gap={'5px'}>
                                                            <JTypography size='label-large'>
                                                                {tex.code}
                                                            </JTypography>
                                                        </Flex>
                                                    </Button>
                                                </ListItem>
                                            )
                                        })}
                                    </List> : null}
                            </FormControl>
                        </Flex>
                        <Flex gap='6px' flexWrap='wrap'>
                            {listInvite.map((list: any, i: number) => {
                                return (
                                    <Tag
                                        size={'sm'}
                                        key={i}
                                        borderRadius='full'
                                        variant='solid'
                                        bg='primary'
                                        color='surface'
                                    >
                                        <TagLabel>{list.code_name}</TagLabel>
                                        <TagCloseButton onClick={() => handleDeleteInvite(list.code_name)} />
                                    </Tag>
                                )
                            })}
                        </Flex>
                    </ModalBody>
                    <ModalFooter
                        gap={"12px"}
                        padding={"0px"}
                        marginTop={"24px"}
                        justifyContent={"center"}
                    >
                        <Flex direction={'row'}>
                            <JButton
                                size="normal"
                                variant="j-primary"
                                type='submit'
                                my={2} mx={4} px={84}
                                onClick={() => {
                                    handleMemberInvite()
                                }}
                            >
                                <JTypography size='label-large'>{t("master_anggota_invite")}</JTypography>
                            </JButton>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Flex >
    )
}

export default ModalMemberInvite